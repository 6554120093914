<template>
<div class="row row-custom">
    <div class="col-md-8">
        <div class="card text-right">
        <div class="card-header">
            تفاصيل الموظف
        </div>
        <div class="card-body">
            <div class="row">   
             <table class="table">
                 <tr>
                     <td>الاسم</td><td>:</td><td>{{User.name}}</td>       
                 </tr>
                 <tr><td>البريد الالكتروني</td><td>:</td><td>{{User.email}}</td></tr>
                 <tr><td>الدور الوظيفي</td><td>:</td><td>{{User.role[0]}}</td></tr>
             </table>
             
 
            
            </div>                      
        </div>
        <template v-if="User.role[0] =='user'">
        <div class="card-header">
            الصلاحيات
        </div>
        <div class="card-body">
           <div class="row">
               <div class="col-md-6 mb-3" v-for="(permission,index) in Permissions" :key="index">
                 <li>{{getTranslate(permission.name)}}</li> 
               </div>
           </div>
        </div>  
          <div class="card-header">
            الاجراءات
        </div>
        <div class="card-body">
           <div class="row">
               <div class="col-md-6" v-for="(permission,index) in ProcudrePermissions" :key="index">
                  <li>{{getTranslate(permission.name)}}</li>
               </div>
           </div>
        </div>  
        </template> 
    </div>

    </div>
</div>

</template>

<script>
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
export default {
      computed:{
    ...mapState({
        User:state=>state.admin.users.User.data.data,
        }),

    Permissions(){
      return this.User.permissions.filter(p=>(p.name !='can_add' && p.name !='can_edit' && p.name !='can_delete'));
   },
   ProcudrePermissions(){
      return this.User.permissions.filter(p=>(p.name =='can_add' || p.name =='can_edit' || p.name =='can_delete'));
   },
      },
      methods:{

        getTranslate(permission){
            const lang={
                'can_add':'اضافة',
                'can_edit':'تعديل',
                'can_delete':'حذف',

                'manage_colors':'الالوان',
                'manage_countries':'الدول',
                'manage_cities':'المدن',
                'manage_status':'الحالات',
                'manage_settings':'الاعدادات',
                'manage_advertisement':'الاعلانات',

                'manage_categories':'التصنيفات',
                'manage_sub_categories':'التصنيفات الفرعية',
                'manage_products':'المنتجات',
                'manage_orders':'الطلبيات',
                'manage_clients':'الزبائن',
                'manage_discount_code':'اكواد الخصم',
                'manage_users':'المستخدمين',
            };
            return lang[permission];
        },  
      },
     beforeRouteEnter(to,from,next){    
        const id = to.params.id;
        store.dispatch('admin/fetchUser', id).then((response) => {
            next(true);
        }).catch(error=>{
            next(false);
        })
    },
}
</script>

<style scoped>
.row-custom{
    justify-content: center;
}

.card .card-header {
    background: #16918b;
    color: white;
}
</style>